export const APP_TITLE = 'Система складского учета';
export const APP_ID = 'ru.westcom.sklad';
export const APP_VERSION = '15.11.24';
export const LOGO_TITLE = '';
export const SITE_URL = 'https://sklad2.westcom.ru';

export const PASSWORD_SALT = 'vb45k';
export const COOKIE_LOGIN = 'login';
export const COOKIE_PASS = 'password';
export const SESSION_LIFETIME = 3600 * 24 * 5;
export const SHOW_SCROLLTOP_OFFSET = 300;
// export const SESSION_LIFETIME = 60 * 60 * 800;//время жизни сессии в секундах
export const DEBUG = /localhost/.test(window.location.href);
let _API_URL = null;
let _SOCKET_URL = null;
if (DEBUG) {
    _API_URL = 'http://localhost/sklad/api/';
    _SOCKET_URL = 'http://localhost:3132';

    // _API_URL = 'https://sklad2.westcom.ru/api/';
    // _SOCKET_URL = 'https://sklad2.westcom.ru:8088/';
} else {
    // _API_URL = 'http://sklad2.westcom.ru/api/';
    // _SOCKET_URL = 'http://82.202.170.56:8088';

    _API_URL = 'https://sklad2.westcom.ru/api/';
    _SOCKET_URL = 'https://sklad2.westcom.ru:8088/';
}
export const API_URL = _API_URL;
export const SOCKET_URL = _SOCKET_URL;

/**
 * Заголовок подразделений
 * @type object
 */
export const DIVISION_TITLE = {
    one: 'Офис',
    many: 'Офисы'
};
export const DIVISION_ID_WESTCOM = 4;
export const DIVISION_ID_TOB = 3;
export const DIVISION_ID_TORGSTORE = 4;
export const DIVISION_ID_SVIBLOVO = DIVISION_ID_TOB;
export const DIVISION_ID_ELECTROZAVODSKAYA = DIVISION_ID_WESTCOM;


export const rolesAdmin = ['admin'];
export const rolesModerator = ['admin', 'moderator'];
export const MENU_TITLE = {
    tasks: {
        one: 'Заявка',
        many: 'Заявки'
    },
    docs: {
        one: 'Накладная',
        many: 'Накладные'
    },
    forms: {
        one: 'Форма',
        many: 'Формы'
    },
    orders: {
        one: 'Заказ',
        many: 'Заказы'
    },
    calls: {
        one: 'Звонок',
        many: 'Звонки'
    },
    vocations: {
        one: 'Календарь отпусков',
        many: 'Календари отпусков'
    },
    logs: {
        one: 'Log',
        many: 'Logs'
    },
    mailbox: {
        one: 'Заявка с info',
        many: 'Заявки с info'
    },
    reports: {
        one: 'Отчет',
        many: 'Отчеты'
    },
    reportProductsSaleWestcom: {
        one: 'Продажи на сайте westcom.ru',
        many: 'Продажи на сайте westcom.ru'
    },
    reportInstallers: {
        one: 'Отчет по монтажникам',
        many: 'Отчеты по монтажникам'
    },
    reportProductsSale: {
        one: 'Продажа на сайте ',
        many: 'Продажи на сайте '
    },
    reportInstalls: {
        one: 'Монтажи',
        many: 'Монтажи'
    },
    sheets: {
        one: 'Таблица',
        many: 'Таблицы',
        bills: {
            one: 'Счет',
            many: 'Счета',
        }
    },
    tables: {
        one: 'Таблица',
        many: 'Таблицы',
        bills: {
            one: 'Счет',
            many: 'Счета',
        }
    },

    taskManager: {
        one: 'Таскменеджер',
        many: 'Таскменеджер',
        url: '/task_manager',
        roles: [...rolesModerator, 'manager', 'buyer']
    }
};
export const FORM_TYPES = {
    zamershik: "Замеры",
    recall: "Обратный звонок",
    shop3d: "Магазины \"под ключ\"",
    cry: "Пожаловаться директору",
    landings_zayavka: "Заявка",
    buy1click: "Покупка в 1 клик ",
    my_sizes: "Расчет по размерам заказчика",
    consult: "Консультация",
    calculator: "Калькулятор",
    missing_calls: "Пропущенный звонок",
    from_mailbox: "Заявка с info",
    order: "Заказ изделия",
    designer: "Вызвать дизайнера"
};
export const ORDERS_TYPES = {
    0: {
        one: 'Новый',
        many: 'Новые'
    },

    5: {
        one: 'В обработке',
        many: 'В обработке'
    },
    10: {
        one: 'Выполнен',
        many: 'Выполнены'
    },
    4: {
        one: 'Отменен',
        many: 'Отменены'
    }
};

let formSubMenu = [];
for (const code in FORM_TYPES) {
    formSubMenu.push({
        name: FORM_TYPES[code],
        url: '/forms/' + code,
        roles: []
    });
}
formSubMenu.push({
    name: 'Все',
    url: '/forms/',
    roles: []
});

let ordersSubMenu = [];
ordersSubMenu.push({
    name: 'Все',
    url: '/orders/',
    roles: []
});
for (const ot in ORDERS_TYPES) {
    ordersSubMenu.push({
        name: ORDERS_TYPES[ot].many,
        url: '/orders/' + ot,
        roles: []
    });
}
ordersSubMenu.push({
    name: 'С сайта tob.ru',
    url: '/orders/site/tob.ru',
    roles: ['admin']
});
export const mailboxSubMenu = [
    {
        id: 'westcom',
        name: 'Вестком',
        url: '/mailbox/westcom',
        roles: []
    },
    {
        id: 'tob',
        name: 'ТОБ',
        url: '/mailbox/tob',
        roles: []
    },
];

export const MENU = [
    // 'separator',
    // {
    // 	name: 'Консоль',
    // 	url: '/',
    // 	ico: 'fa-tachometer',
    // 	roles: []
    // },
    {
        name: MENU_TITLE.tasks.many,
        url: '/tasks',
        ico: 'fa-tasks',
        roles: []
    },
    {
        name: MENU_TITLE.docs.many,
        url: '/docs',
        ico: 'fa-inbox',
        roles: []//...rolesModerator]
    },
    {
        name: MENU_TITLE.orders.many,
        url: '/orders',
        ico: 'fa-shopping-basket',
        roles: [...rolesModerator, 'manager'],
        // divisions: [4],
        menu: ordersSubMenu
    },
    {
        name: MENU_TITLE.tables.bills.many,
        url: '/tables/bills',
        ico: 'fa-money',
        roles: [...rolesModerator, 'manager', 'user'],
    },
    // {
    // 	name: MENU_TITLE.sheets.many + ' (старое)',
    // 	url: '/sheets/bills',
    // 	ico: 'fa-table',
    // 	roles: [...rolesModerator, 'manager', 'user'],
    // 	menu: [
    // 		{
    // 			name: 'Счета',
    // 			url: '/sheets/bills',
    // 			ico: 'fa-money',
    // 			roles: []
    // 		},
    // 	]
    // },
    {
        name: MENU_TITLE.forms.many,
        url: '/forms',
        ico: 'fa-comments-o',
        roles: [...rolesModerator, 'manager'],
        menu: formSubMenu
    },
    {
        name: MENU_TITLE.mailbox.many,
        url: '/mailbox',
        ico: 'fa-envelope-o',
        roles: [...rolesModerator],
        // divisions: [4],
        menu: mailboxSubMenu
    },
    //показывать с 23/09/24
    {
    	name: 'Замеры',
    	url: '/tasks/todo/zamer',
    	ico: 'fa-expand',
    	roles: [...rolesModerator, 'manager', 'buyer'],
    	divisions: [4]
    },
    {
        name: MENU_TITLE.taskManager.many,
        url: MENU_TITLE.taskManager.url,
        ico: 'fa-thumb-tack',
        // roles: [...rolesAdmin]
        roles: MENU_TITLE.taskManager.roles,
        // divisions: [4]
    },

    {
        name: MENU_TITLE.calls.many,
        url: '/calls',
        ico: 'fa-phone',
        roles: [...rolesModerator, 'manager', 'buyer']
    },
    //28/07/2022
    {
        name: MENU_TITLE.vocations.one,
        url: '/vocations',
        ico: 'fa-sun-o',
        // divisions: [4],
        roles: [...rolesModerator, 'manager', 'buyer']
    },
    {
        name: 'Пользователи',
        url: '/user',
        ico: 'fa-users',
        roles: [...rolesModerator, 'manager'],
        menu: [
            // {
            // 	name: 'Все пользователи',
            // 	url: '/user',
            // 	ico: 'fa-user',
            // 	roles: [],
            // 	menu: [
            // 		{
            // 			name: '123',
            // 			url: '/asd',
            // 			roles: []
            // 		}
            // 	]
            // },
            {
                name: DIVISION_TITLE.many,
                url: '/user/divisions',
                ico: 'fa-building-o',
                roles: [...rolesAdmin]
            },
            {
                name: 'Клиенты',
                url: '/user/clients',
                ico: 'fa-address-book-o',
                roles: [...rolesModerator, 'manager']
            },
        ]
    },

    // {
    // 	name: 'Пользователи Admin',
    // 	url: '/user/role/admin',
    // 	roles: []
    // },
    // {
    // 	name: 'Пользователи manager',
    // 	url: '/user/role/manager',
    // 	roles: []
    // },
    {
        name: 'Настройки',
        url: '/settings',
        ico: 'fa-cogs',
        roles: [...rolesAdmin],
        menu: [
            {
                name: 'Основные',
                url: '/settings/general',
                ico: 'fa-cogs',
                roles: [...rolesAdmin]
            },
            {
                name: 'Календарь',
                url: '/settings/calendar',
                ico: 'fa-calendar',
                roles: [...rolesAdmin]
            },
            {
                name: 'Ограничения',
                url: '/settings/limits',
                ico: 'fa-ban',
                roles: [...rolesAdmin]
            },
            {
                name: 'Уведомления (письма)',
                url: '/settings/notifications',
                ico: 'fa-envelope',
                roles: [...rolesAdmin]
            },
            // {
            // 	name: MENU_TITLE.tasks.many,
            // 	url: '/settings/tasks',
            // 	ico: 'fa-tasks',
            // 	roles: [...rolesAdmin]
            // },
        ]
    },
    {
        name: 'Устав компании',
        url: '/ustav',
        ico: 'fa-file-text-o',
        roles: [...rolesModerator, 'manager'],
    },
    {
        name: MENU_TITLE.reports.many,
        url: '/reports',
        ico: 'fa-pie-chart',
        roles: [...rolesModerator, 'buyer'],
        // roles: [...rolesModerator, 'manager', 'buyer'],
        menu: [
            // {
            // 	name: MENU_TITLE.reportProductsSaleWestcom.one,
            // 	url: '/reports/productsSaleWestcom',
            // 	ico: 'fa-shopping-cart',
            // 	roles: [],
            // 	divisions: [4],
            // },
            {
                name: MENU_TITLE.reportProductsSale.many + 'westcom.ru',
                url: '/reports/productsSale/westcom.ru',
                ico: 'fa-shopping-cart',
                img: 'https://westcom.ru/themes/wc/img/logo.png',
                roles: [...rolesAdmin, 'buyer'],
                divisions: [DIVISION_ID_WESTCOM],
            },
            {
                name: MENU_TITLE.reportProductsSale.many + 'torgstore.ru',
                url: '/reports/productsSale/torgstore.ru',
                ico: 'fa-shopping-cart',
                img: 'https://torgstore.ru/themes/torg/img/logo.svg',
                roles: [...rolesAdmin, 'buyer'],
                divisions: [DIVISION_ID_TORGSTORE],
            },
            {
                name: MENU_TITLE.reportProductsSale.many + 'tob.ru',
                url: '/reports/productsSale/tob.ru',
                ico: 'fa-shopping-cart',
                img: 'https://tob.ru/images/logo_sm_new.svg',
                roles: [...rolesAdmin, 'buyer'],
                divisions: [DIVISION_ID_TOB],
            },
            {
                name: MENU_TITLE.reportInstallers.one,
                url: '/reports/installers',
                ico: 'fa-users',
                roles: []
            },
            {
                name: MENU_TITLE.reportInstalls.many,
                url: '/reports/installs',
                ico: 'fa-camera',
                roles: []
            },
        ]
    },
    {
        name: MENU_TITLE.logs.many,
        url: '/logs',
        ico: 'fa-history',
        roles: [...rolesAdmin]
    },
];

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];
export const DAYS_SHORT = [
    'пн',
    'вт',
    'ср',
    'чт',
    'пт',
    'сб',
    'вс'
];
export const DAYS_FULL = [
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
    'воскресенье'
];
// export const TASKS_TODO_DOSTAVKA = 1;
// export const TASKS_TODO_INSTALL = 2;
// export const TASKS_TODO_ONE_INSTALLER = 3;
// export const TASKS_TODO_TWO_INSTALLER = 4;
export const MESSAGES = {
    confirmAction: 'Подтвердите действие',
    confirmDelete: 'Подтвердите удаление',
    accessDenied: 'У вас не хватает прав для просмотра'
};
export const IMG_EXTENSIONS = [
    'jpg', 'jpeg',
    'bmp', 'png', 'gif',
    'webp'
];

///
// const FORMS_STATUS_NEW = 0;//новый, только пришел с сайта
// const FORMS_STATUS_DONE = 10;//выполнен
// const FORMS_STATUS_DELETED = 3;//удален
// const FORMS_STATUS_CANCELLED = 4;//отменен
// const FORMS_STATUS_PROCESS = 5;//в обработке
// export const FORMS_STATUS = {
// 	0: 'новый',
// 	10: 'обработана',
// 	3: 'удален',
//
// 	5: 'в обработке',
//
// 	40: 'отменен - не готовы ждать',
// 	41: 'отменен - не устроила доставка',
// 	42: 'отменен - не утсроила предоплата',
// 	// 4: 'отменен',
// 	4: 'отменен - другое в примечании',
// };
export const FORMS_STATUS = new Map([
    [0, 'новая'],
    [5, 'в обработке'],
    [10, 'выполнена'],
    // [3, 'удалена'],

    [40, 'отменен - не готовы ждать'],
    [41, 'отменен - не устроила доставка'],
    [42, 'отменен - не устроила предоплата'],
    [4, 'отменен - другое в примечании'],
]);

export const FORMS_STATUS_STYLE = new Map([
    [5, {backgroundColor: 'yellow'}],
    [10, {backgroundColor: '#28A745'}],

    [40, {backgroundColor: '#e31d24'}],
    [41, {backgroundColor: '#e31d24'}],
    [42, {backgroundColor: '#e31d24'}],
    [4, {backgroundColor: '#e31d24'}],
]);
export const ORDERS_STATUS_CANCELLED = 4;//отменен

export const ORDERS_STATUS = new Map([
    [0, 'новый'],
    [5, 'в обработке'],
    [10, 'выполнен'],
    // [4, 'отменен'],
    [40, 'отменен - не готовы ждать'],
    [41, 'отменен - не устроила доставка'],
    [42, 'отменен - не устроила предоплата'],
    [4, 'отменен - другое в примечании'],
    // [20, 'обзвонить'],
]);
export const ORDERS_STATUS_STYLE = new Map([
    // [0, null],
    [5, {backgroundColor: 'yellow'}],
    [10, {backgroundColor: '#28A745'}],

    [40, {backgroundColor: '#e31d24'}],
    [41, {backgroundColor: '#e31d24'}],
    [42, {backgroundColor: '#e31d24'}],
    [4, {backgroundColor: '#e31d24'}],
    // [20, {backgroundColor: 'yellow'}],
]);
// export const ORDERS_STATUS = {
// 	0: 'новый',
// 	5:'в обработке',
// 	10: 'выполнен',
// 	4: 'отменен'
// };

export const LIMITS_DELIVERY_BY_DAY = 100;
export const LIMITS_INSTALL_BY_DAY = 10;


export const SKIP_SEARCH_ACTION_NAME = 'skip_search_string';

export const TASK_MANAGER_MENU_COUNTER_REFRESH_TIME = 5 * 60000;
// export const TASK_MANAGER_MENU_COUNTER_REFRESH_TIME = 5000;
export const TASK_MANAGER_STATUS = {
    work: {
        id: 0,
        name: 'в работе'
    },
    done: {
        id: 1,
        name: 'скрыт'
    }
};
export const TASK_MANAGER_STATUS_TYPE = {
    todo: 'todo',
    tasks: 'tasks',
    installs: 'installs',
    orders: 'orders',
    forms: 'forms'
};
