import React, {createRef, useState, useEffect} from 'react';
// import {useParams} from "react-router-dom";
import {Win, Button} from "components/_common";
// import {DIVISION_TITLE} from "utils/config";
// import {toastr} from "react-redux-toastr";
import {FormItem} from "components/_common";


const DivisionEdit = (props) => {
	const {id, division, formRef, onSubmit} = props;


	let divData = '';
	if (division && division.data)
		divData = JSON.stringify(division.data);

	return (
		<>
			<Win
				header={id ? 'Редактирование' : 'Добавление'}
				footer={
					<div className={"win-ftr-btns"}>
						<Button type="apply"  onClick={() => onSubmit(0)}/>
						<Button type="save" onClick={() => onSubmit(1)}/>
						<Button type="close" onClick={() => props.history.push('/user/divisions')}/>
					</div>
				}

				onClose={() => props.history.push('/user/divisions')}
			>
				<form ref={formRef}>
					<FormItem
						name={"name"}
						label={"Название"}
						defVal={division && division.name}
						isRequire={true}
					/>
					<FormItem
						name={"tels"}
						label={"Внутренние телефонные номера"}
						defVal={division && division.tels}
						isRequire={true}
						descr={"номера через запятую"}
					/>
					<FormItem
						fieldType={"textarea"}
						name={"data"}
						label={"Дополнительные данные"}
						defVal={divData}
						descr={"массивв формате JSON"}
					/>
					<FormItem
						fieldType={"textarea"}
						name={"domains"}
						label={"Сайты"}
						defVal={(division && division.domains) ? division.domains.join("\n") : ""}
						descr={"Доменные имена, без http(s) и www. Разделитель: перевод строки (enter)"}
					/>
					{/*<div className="form-item">*/}
					{/*	<label>Название*</label>*/}
					{/*	<input type="text" name={"name"} defaultValue={division && division.name} />*/}
					{/*</div>*/}
					{/*<div className="form-item">*/}
					{/*	<label>Внутренние телефонные номера*</label>*/}
					{/*	<input type="text" name={"tels"} defaultValue={division && division.tels} />*/}
					{/*	<div className="descr">*/}
					{/*		номера через запятую*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*<div className="form-item">*/}
					{/*	<label>Дополнительные данные</label>*/}
					{/*	<textarea name={"data"} defaultValue={divData}></textarea>*/}
					{/*	<div className="descr">*/}
					{/*		массивв формате JSON*/}
					{/*	</div>*/}
					{/*</div>*/}
				</form>
			</Win>

		</>
	);
};


export default DivisionEdit;