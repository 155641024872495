import React, {createRef, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import Api, {apiAction} from "utils/Api";
import {ContentHeader} from "components/_common";
import {DIVISION_TITLE} from "utils/config";
import {DivisionEdit} from "components/user";

const DivisionsEditContainer = props => {
	let { id } = useParams();
	id = parseInt(id);

	const {user, actions} = props;


	const [division, setDivision] = useState({});

	const onLoad = async (isSubscribe) => {
		if (!isSubscribe)
			return;

		actions.showLoading();

		const res = await Api.post('users.php',  {
			action: 'getDivisions',
			user: user,
			id: id
		});

		apiAction(res, (data) => {
			setDivision(data);
		});
		actions.hideLoading();
	};
	useEffect(() => {
		let isSubscribe = true;
		onLoad(isSubscribe).then(r => {});
		return () => isSubscribe = false
	}, []);

	const formRef = createRef();
	const onSubmit = async (isReturn = 0) => {
		const form = formRef.current;

		let err = [];
		if (form.name.value === '')
			err.push('Впишите название');
		if (form.tels.value === '')
			err.push('Впишите телефонные номера');

		if (err.length) {
			{err.map((txt, i) => {
				toastr.error('Ошибка заполнения', txt);
			})}
		}
		else {
			actions.showLoading();

			const res = await Api.post('users.php',  {
				action: 'saveDivision',
				user: user,
				data: {
					id: id || 0,
					name: form.name.value,
					tels: form.tels.value,
					data: form.data.value,
					domains: form.domains.value
				}
			});
			apiAction(res, (data) => {
				if (isReturn)
					props.history.push('/user/divisions')

			});
			actions.hideLoading();
		}

	};
	return (
		<>
			<ContentHeader
				parent={[
					{url: '/user', name: "Пользователи"},
					// {url: '/user/divisions', name: DIVISION_TITLE.many},
				]}
				title={DIVISION_TITLE.many}
			/>
			<DivisionEdit
				id={id}
				division={division}
				formRef={formRef}
				onSubmit={onSubmit}
				{...props}
			/>
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DivisionsEditContainer);