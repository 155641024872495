import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {ContentHeader} from "components/_common";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {MENU_TITLE} from "utils/config";
import {is_empty} from "utils/func";

const sortByDivision = (userDivId, callsByDivision) => {

	let newCalls = [];
	if (callsByDivision[userDivId])
		newCalls.push({
			...callsByDivision[userDivId],
			divId: userDivId
		});

	for (let divId in callsByDivision) {
		divId = parseInt(divId);
		if (divId !== userDivId)
			newCalls.push({
				...callsByDivision[divId],
				divId: divId
			});
	}

	return newCalls;
}


const CallsContainer = props => {

	const {user, GUI, actions} = props;
	const [callsByDivision, setCallsByDivision] = useState({});

	let apiActionProcess = false;

	const getCalls = async (isSubscribe = true, calDates = null, search = '') => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('calls.php', {
				action: 'getCalls',
				user: user,
				dates: calDates ? calDates : GUI.calendarDates,
				search: search
			});

			apiAction(res, (data) => {
				setCallsByDivision(
					sortByDivision(parseInt(user.divId), data)
				);
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});


		}
	};
	const onCalendarSelectDate = (dates) => {
		getCalls(true, dates).then();
	};
	const doSearch = str => {
		getCalls(true, [], str).then(null);
	};

	useEffect(() => {
			let isSubscribe = true;
			getCalls(isSubscribe).then(null);
			actions.setCalendarSelectDateAction(onCalendarSelectDate);
			actions.setSearchAction(doSearch);
			return () => {
				isSubscribe = false;
				//отключаем функциию обработчики
				actions.setCalendarSelectDateAction(null);
				actions.setSearchAction(null);
			}
		},
		[]
	);

	const tblHeader = (
		<thead>
		<tr>
			<td
				// width={"5%"}
				align={"center"}
				className={"col-num"}
			>
				№
			</td>
			<td
				// width={"20%"}
				className={"col-date"}>
				Дата
			</td>
			<td
				// width={"20%"}
				className={"col-tel"}>
				Номер
			</td>
			<td
				// width={"5%"}
				className={"col-inner"}>
				Внутр.
			</td>
			<td
				// width={"20%"}
				className={"col-status"}>
				Статус
			</td>
			<td
				// width={"20%"}
				className={"col-time"}>
				Время разговора
			</td>
		</tr>
		</thead>
	);

	const printTable = (calls, ctype) => {
		let i = 0;
		return <table
			className={"calls-tbl tbl dark -bordered mt20"}
			key={"calls-tbl-" + ctype}
		>
			{tblHeader}
			<tbody key={"tbl-body-" + ctype}>
			{
				Object.keys(calls).map(tel => {
					const rowSpan = calls[tel].length;
					return calls[tel].map((call, j) => {
						// console.log(call);
						if (!j)
							i++;
						return <tr key={"calls-tbl-tr-" + ctype + i + j}>
							{
								!j ? <td rowSpan={rowSpan} align={"center"}>
									{i}
								</td> : null
							}
							<td className={"col-date"}>
								{call.calldate}
							</td>
							{
								!j ? <td rowSpan={rowSpan} className={"col-tel"}>
									{call.ftel}
								</td> : null
							}

							<td className={"col-inner"}>
								{
									call.finner != ''
										? call.finner
										: call.inner
								}
								{/*{call.inner} / f={call.finner}*/}
							</td>
							<td className={"col-status"}>
								{call.status}
							</td>
							<td className={"col-time"}>
								{call.time}
							</td>
						</tr>;
					});

					return null;
				})
			}
			</tbody>
		</table>;
	};


	let content = [];

	let callsSummInfo = [];
	let totalDivs = callsByDivision.length;


	// console.log(totalDivs);
	if (!is_empty(callsByDivision)) {
		// console.clear();
		// console.dir(callsByDivision[0]['callsIn']['9209466668']);//

		callsByDivision.map((calls, i) => {

			let callsInPhones = Object.keys(calls.callsIn).length;
			let callsOutPhones = Object.keys(calls.callsOut).length;

			callsSummInfo.push(
				<div
					key={"calls-div-summ-" + i}
					className={"calls-div-summ col-" + totalDivs}>
					<h4>
						{calls.name}
					</h4>
					<div className="-s">
						<a href={`#calls-${calls.divId}-in`}>Входящие: {callsInPhones}</a>
						{/*звонков - {callsByDivision[divId].callsInCount}, номеров - {callsInPhones}*/}
						<br/>
						<a href={`#calls-${calls.divId}-out`}>Исходящие: {callsOutPhones}</a>
						{/*звонков - {callsByDivision[divId].callsOutCount}, номеров - {callsOutPhones}*/}
					</div>
					<br/>
				</div>
			);

			return null;
		});
		content.push(
			<div className="row calls-summ" key={"calls-summ"}>
				{callsSummInfo}
			</div>
		)

		callsByDivision.map((calls, i) => {
			const callsInPhones = Object.keys(calls.callsIn);
			const callsOutPhones = Object.keys(calls.callsOut);

			const callsInCount = callsInPhones.length;
			const callsOutCount = callsOutPhones.length;

			const tblIn = callsInCount
				? printTable(calls.callsIn, calls.divId + 'in')
				: null;
			const tblOut = callsInCount ? printTable(calls.callsOut, calls.divId + 'out') : null;

			if (callsInCount || callsOutCount)
				content.push(
					<div
						key={"calls-div-name-" + calls.divId}
						className={"calls-div-item"}>
						<div className="hdr">
							<h2>
								{calls.name}
							</h2>
						</div>
						{
							callsInCount ? (
								<>
									<a name={`calls-${calls.divId}-in`}></a>
									<div className="-s mt20">

										<strong>
											Входящие: {callsInCount}
											{/*звонков - {callsInCount}, номеров - {callsInCount}*/}
										</strong>
									</div>

									{tblIn}
								</>
							) : null
						}
						{
							callsOutCount ? (
								<>
									<a name={`calls-${calls.divId}-out`}></a>
									<div className="-s mt20">

										<strong>
											Исходящие: {callsOutCount}
											{/*звонков - {callsOutCount}, номеров - {callsOutCount}*/}
										</strong>
									</div>

									{tblOut}
								</>
							) : null
						}


					</div>
				);

			return null;
		});

		// if (0)
		// for (let divId in callsByDivision) {
		// 	const callsInPhones = Object.keys(callsByDivision[divId].callsIn);
		// 	const callsOutPhones = Object.keys(callsByDivision[divId].callsOut);
		// 	// console.log(callsByDivision[divId].callsIn);
		// 	const callsInCount = callsInPhones.length;
		// 	const callsOutCount = callsOutPhones.length;
		//
		// 	const tblIn = callsInCount
		// 		? printTable(callsByDivision[divId].callsIn, divId + 'in')
		// 		: null;
		// 	const tblOut = callsInCount ? printTable(callsByDivision[divId].callsOut, divId + 'out') : null;
		//
		// 	if (callsByDivision[divId].callsInCount || callsByDivision[divId].callsOutCount)
		// 		content.push(
		// 			<div
		// 				key={"calls-div-name-" + divId}
		// 				className={"calls-div-item"}>
		// 				<div className="hdr">
		// 					<h2>
		// 						{callsByDivision[divId].name}
		// 					</h2>
		// 				</div>
		// 				{
		// 					callsByDivision[divId].callsInCount ? (
		// 						<>
		// 							<div className="-s mt20">
		// 								<strong>
		// 									Входящие: звонков - {callsByDivision[divId].callsInCount}, номеров - {callsInCount}
		// 								</strong>
		// 							</div>
		// 							{tblIn}
		// 						</>
		// 					) : null
		// 				}
		// 				{
		// 					callsByDivision[divId].callsOutCount ? (
		// 						<>
		// 							<div className="-s mt20">
		// 								<strong>
		// 									Исходящие: звонков - {callsByDivision[divId].callsOutCount}, номеров - {callsOutCount}
		// 								</strong>
		// 							</div>
		// 							{tblOut}
		// 						</>
		// 					) : null
		// 				}
		//
		//
		// 			</div>
		// 		)
		// }
	}

	return (
		<>
			<ContentHeader
				title={MENU_TITLE.calls.many}
				showDates={true}
				datesAction={getCalls}
			/>
			{content}
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CallsContainer);
