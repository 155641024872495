import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {ContentHeader} from "components/_common";
import {MENU_TITLE, mailboxSubMenu} from "utils/config";
import Api, {apiAction} from "utils/Api";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import MailboxListItem from "components/mailbox/MailboxListItem";
import {toastr} from "react-redux-toastr";

const API_URL = 'mailbox.php';

const MailboxContainer = props => {

	const {user, GUI, actions} = props;

	let {id: mailboxId = mailboxSubMenu[0].id} = useParams();


	let isSubscribe = true;

	const [emails, setEmails] = useState([]);

	let apiActionProcess = false;
	const getData = async (isSubscribe = true, calDates = null, search = '') => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			try {
				const res = await Api.post(API_URL, {
					action: 'getEmails',
					userId: user.id,
					dates: calDates ? calDates : GUI.calendarDates,
					search: search,
					id: mailboxId
				});

				apiAction(res, (data) => {
					// console.log(res);
					setEmails(data.emails);
				}, () => {
					apiActionProcess = false;
					actions.hideLoading();
				});
			}
			 catch (e) {
				 toastr.error('Ошибка', e.message);
				 actions.hideLoading();
			 }


		}
	};
	// console.log(typeof isSubscribe);


	const sendToForms = async (number) => {
		if (!isSubscribe)
			return;
		console.clear();

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;
			const res = await Api.post(API_URL, {
				action: 'send2form',
				userId: user.id,
				msgNumber: number
			});

			apiAction(res, () => {


			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	}
	const deleteEmail = async (number) => {
		if (!isSubscribe)
			return;

		console.clear();
		// setEmails(emails.filter(eml => eml.number !== number));

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;
			const res = await Api.post(API_URL, {
				action: 'delete',
				userId: user.id,
				msgNumber: number
			});

			apiAction(res, () => {
				setEmails(emails.filter(eml => eml.number !== number));

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	}


	useEffect(() => {
			isSubscribe = true;
			// if (!DEBUG)
			getData(isSubscribe).then(null);

			return () => {
				isSubscribe = false;
			}
		},
		[mailboxId]
	);

	let emailsList = null;

	if (emails.length) {

		emailsList = (
			<div className="mt20">
				<div className="row">
					<div className="col-2">
						Всего: {emails.length}
					</div>

				</div>
				{emails.map(item =>
					<MailboxListItem
						key={item.id}
						data={item}
						sendToForms={sendToForms}
						deleteEmail={deleteEmail}
					/>
				)}
				{/*<div className={"mailbox-list tbl dark hdr-cntr -bordered"}>*/}
				{/*	<div className="thead">*/}
				{/*		<ul className="tr">*/}
				{/*			<li className="td col-date">*/}
				{/*				Дата*/}
				{/*			</li>*/}
				{/*			<li className="td col-email">*/}
				{/*				Email*/}
				{/*			</li>*/}
				{/*			<li className="td col-subject">*/}
				{/*				Тема*/}
				{/*			</li>*/}
				{/*			<li className="td col-txt">*/}
				{/*				Содержимое*/}
				{/*			</li>*/}
				{/*			<li className="td col-acts">*/}
				{/*				Действия*/}
				{/*			</li>*/}
				{/*		</ul>*/}
				{/*	</div>*/}
				{/*	<div className="tbody">*/}
				{/*		{emails.map(item =>*/}
				{/*			<MailboxListItem*/}
				{/*				key={item.id}*/}
				{/*				data={item}*/}
				{/*				sendToForms={sendToForms}*/}
				{/*				deleteEmail={deleteEmail}*/}
				{/*			/>*/}
				{/*		)}*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		);
	}
	let contentHeaderTitle = MENU_TITLE.mailbox.many;
	for (let mb of mailboxSubMenu) {
		if (mb.id == mailboxId)
			contentHeaderTitle += ` ${mb.name}`;
	}

	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				showDates={false}
				datesAction={null}
				// parent={null}
			/>
			{/*{*/}
			{/*	DEBUG ? <h2>Данные не могут быть получены с локального компьютера</h2> : null*/}
			{/*}*/}
			{emailsList}
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(MailboxContainer);
