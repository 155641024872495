import React, {useState} from 'react';
import {FormItem, Button, BUTTON_TYPE} from "components/_common";
import {copyClipboard, in_array, is_empty} from "utils/func";
import uniqid from "uniqid";

/**
 * Доп компании в заявке
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TasksCompany = props => {
    const {
        edit = false,
        companySelectContent = null,
        hideFields, requireFields,
        commentsPredefined,
        data = [],
        setTasksData = null,
        deliveryType = ''
    } = props;


    // console.log(data);
    // const [total, setTotal] = useState(data.length);
    const [companyList, setCompanyList] = useState(data);


    const onDelete = (indexDel) => {
        console.log(`onDelete ${indexDel}`);
        let companyListNew = [];
        companyList.map((c, i) => {
            console.log(i,c);
            if (i != indexDel) {
                console.log(`add ${i}`, c);
                companyListNew.push(c);
            }
        });

        setCompanyList(companyListNew);
    };

    let companyListComponents = [];
    if (typeof companyList == 'object') {
        companyList.map((company, i) => {
            companyListComponents.push(
                <TasksCompanyFields
                    key={`TasksCompanyFields-${i}`}
                    fieldsSuffix={i}
                    edit={edit}
                    // companySelectContent={companySelectContent}
                    hideFields={hideFields}
                    requireFields={requireFields}
                    company={company.company ? company.company : ''}
                    name={company.name ? company.name : ''}
                    nakl={company.nakl ? company.nakl : ''}
                    address={company.address ? company.address : ''}
                    comments={company.cmnts ? company.cmnts : ''}
                    phoneMobile={company.phoneMobile ? company.phoneMobile : ''}
                    phones={company.phones ? company.phones : ''}
                    commentsPredefined={commentsPredefined}
                    market_num={company.market_num ? company.market_num : ''}
                    deliveryType={deliveryType}
                    onDelete={!is_empty(company) ? onDelete : null}
                />
            );
        });
    }


    return (
        <>
            {companyListComponents}

            <Button
                type={BUTTON_TYPE.applyIco}
                ico={'plus-square'}
                title={"Компания"}
                onClick={() => {
                    // setTotal(total + 1)
                    let newCL = [...companyList];
                    newCL.push({});

                    setCompanyList(newCL);
                }}
            />
        </>
    )

};

export default TasksCompany;


export const TasksCompanyFields = props => {
    const {
        fieldsSuffix = '',// суффикс, используется для доп полей компаний
        edit = false,
        onDelete = false,
        companySelectContent = null,
        hideFields, requireFields,
        company = '',
        name = '',
        nakl = '',
        address = '',
        phoneMobile = '',
        phones = '',
        comments = '', commentsPredefined = null,
        market_num = '',//21/06/24
        deliveryType='',//210624
    } = props;

    // console.log(deliveryType);
    return (
        <div className={"task-edit-company-list-item"}>
            {/*{fieldsSuffix}*/}
            {
                onDelete
                    ? <i className="b fa fa-close" onClick={() => onDelete(fieldsSuffix)}/>
                    : null
            }
            <div className="row">
                <div className="col-2">
                    <FormItem
                        edit={edit}
                        name={`company${fieldsSuffix}`}
                        label={"Компания"}
                        defVal={company}
                        isRequire={in_array("company", requireFields)}
                        descr={companySelectContent}
                        hideFields={hideFields}
                        ico={"building-o"}
                    />
                </div>
                <div className="col-2">
                    <FormItem
                        edit={edit}
                        name={`name${fieldsSuffix}`}
                        label={"Контактное лицо"}
                        defVal={name}
                        isRequire={in_array("name", requireFields)}
                        hideFields={hideFields}
                        ico={"id-card-o"}
                    />
                </div>
            </div>

            <FormItem
                edit={edit}
                name={`nakl${fieldsSuffix}`}
                label={"Номера накладных"}
                defVal={nakl}
                isRequire={in_array("nakl", requireFields)}
                hideFields={hideFields}
                ico={"files-o"}
                descr={"через запятую"}
            />
            {
                in_array(deliveryType, ['mp_msk', 'mp_tk'])
                ? <FormItem
                        edit={edit}
                        name={`market_num${fieldsSuffix}`}
                        label={"Номера заданий"}
                        defVal={market_num}
                        isRequire={in_array("market_num", requireFields)}
                        hideFields={hideFields}
                        ico={"hashtag"}
                        // descr={"через запятую"}
                    />
                    : null
            }





            <div className="row">
                <div className="col-2">
                    <FormItem
                        edit={edit}
                        name={`phoneMobile${fieldsSuffix}`}
                        label={"Мобильный телефон"}
                        descr={"для отправки СМС"}
                        defVal={phoneMobile}
                        isRequire={in_array("phoneMobile", requireFields)}
                        hideFields={hideFields}
                        mask={"(999) 999-99-99"}
                        placeholder={"(903) 123-45-67"}
                        ico={"mobile"}
                        icoRight={{
                            ico: 'clone', tip: 'Скопировать в буфер обмена', onClick(e, input) {
                                if (input.value.trim() !== '')
                                    copyClipboard('8' + input.value);

                            }
                        }}
                    />
                </div>
                <div className="col-2">
                    <FormItem
                        edit={edit}
                        name={`phones${fieldsSuffix}`}
                        label={"Доп. телефоны"}
                        descr={"через запятую"}
                        defVal={phones}
                        isRequire={in_array("phones", requireFields)}
                        hideFields={hideFields}
                        ico={"phone"}
                    />
                </div>
            </div>

            <FormItem
                edit={edit}
                fieldType={"txt"}
                name={`cmnts${fieldsSuffix}`}
                label={"Примечание"}
                defVal={comments}
                isRequire={in_array("cmnts", requireFields)}
                hideFields={hideFields}
                predefined={commentsPredefined ? commentsPredefined.values : null}
                predefinedAdd={commentsPredefined ? commentsPredefined.add : false}
                predefinedAddSeparator={commentsPredefined ? commentsPredefined.separator : ', '}
            />

            <FormItem
                edit={edit}
                fieldType={"txt"}
                name={`address${fieldsSuffix}`}
                label={"Адрес"}
                defVal={address}
                isRequire={in_array("address", requireFields)}
                hideFields={hideFields}
                ico={"map-marker"}
            />

        </div>
    );
}